import * as React from "react"
import Layout from "../layouts/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import '../assets/scss/global.scss'
import './blog.scss'
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import ShareButtons from '../components/socialsharing/Socialsharing'
import { helmetJsonLdProp } from "react-schemaorg";
import { BlogPosting } from "schema-dts";
import { motion } from 'framer-motion'

function slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"
    const newText = text.split('').map(
        (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

const BlogPage = (props) => {


    const siteMeta = useStaticQuery(graphql`
    {
    site {
      siteMetadata {
        siteUrl
      }
    }
    }
    `)
console.log(props.pageContext.data.featuredImage.node.localFile);
    const { data } = props.pageContext
    const cleanTextForMetaDescription = data.excerpt.replace(/<\/?[^>]+(>|$)/g, "");
    const ImageForMetaDescription = `${props.pageContext.data.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`
    const baseuRL = siteMeta.site.siteMetadata?.siteUrl
    console.log(data);
    const jsonLd = helmetJsonLdProp({
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": props.pageContext.data.title,
        "image": baseuRL + ImageForMetaDescription,
        "datePublished": props.pageContext.data.date,
        "dateModified": props.pageContext.data.modified,
        "author": [{
            "@type": "Person",
            "name": "Admin",
            "url": "https://jampot.it"
        }]
    })
    const featuredImage = getImage(props.pageContext.data.featuredImage.node.localFile)
    return (
        <>
            <Helmet script={[jsonLd]}>
                <meta charSet="utf-8" />
                <title>{props.pageContext.data.title}</title>
                <meta name="description" content={props.pageContext.data.excerpt} />
                <meta property="og:title" content={`${props.pageContext.data.title}`} />
                <meta property="og:description" content={`${cleanTextForMetaDescription}`} />
                <meta property="og:url" content={`${props.location.href}`} />
                <meta property="og:image" content={`${baseuRL}${ImageForMetaDescription}`} />
                <meta property="og:image:secure_url" content={`${baseuRL}${ImageForMetaDescription}`} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={`${props.pageContext.data.title}`} />
                <meta name="twitter:description" content={`${cleanTextForMetaDescription}`} />
                <meta name="twitter:image" content={`${baseuRL}${ImageForMetaDescription}`} /> 
            </Helmet>
            <Layout>
                <motion.div
                    initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        duration: 1,
                        delay: 0
                    }}
                    className="wrapperpost-layout">

                    <div className="wrapperpost-content">
                        <motion.div
                            initial={{ y: 20, opacity: 0, zIndex: -1 }}
                            animate={{ y: 0, opacity: 1, zIndex: 0 }}
                            transition={{
                                type: "spring",
                                stiffness: 200,
                                damping: 20,
                                duration: 0.5,
                                delay: 1
                            }}
                            className="backbutton-wrapper-scheda">
                            <Link to="/blog">Back</Link>
                        </motion.div>
                        <ShareButtons url={props.location.href} />
                        <h1>{props.pageContext.data.title}</h1>
                        <div className={`tipoarticolo ${slugify(data.Blog_campi.tipoDiArticolo)}`}>{data.Blog_campi.tipoDiArticolo}</div>
                        <div className="author">By: {data.author.node.name}</div>
                        <div className="immagine-principale">
                            {
                                props.pageContext.data.featuredImage &&
                                <GatsbyImage image={featuredImage} />}

                            {data.Blog_campi.creditImmagine && <div className="credits-immagine-blog" dangerouslySetInnerHTML={{ __html: data.Blog_campi.creditImmagine }} />}
                        </div>
                        <div className="wrapperinnerpost" dangerouslySetInnerHTML={{ __html: props.pageContext.data.content }} />

                        <div className="wrapperinnerpost note" dangerouslySetInnerHTML={{ __html: data.Blog_campi.note }} />

                        <ShareButtons url={props.location.href} />
                    </div>
                </motion.div>
            </Layout>
        </>
    )

}

export default BlogPage